import {
  DOMAIN_ONSHIP, DOMAIN_ONSHIP_STAGE, DOMAIN_PIVOTEL, DOMAIN_STATIONSATCOM, ONECARE, ONECARE_LANDING, ONECARE_PROD_URL,
  ONECARE_PROD_URL_NEW, ONECARE_STAGE_URL, ONSHIP_LANDING, ONSHIP_NEW_STAGE2_URL, ONSHIP_PROD_URL, ONSHIP_STAGE_URL, PIVOTEL_LANDING, PIVOTEL_PROD_URL, PIVOTEL_STAGE_URL, SIGMA, SIGMA_LANDING,
  STAGE_8_THURAYA_URL,
  STATIONSATCOM, STATIONSATCOM_PROD_URL, STATION_SATCOM_LANDING, THURAYA_DOMAIN, THURAYA_LANDING, THURAYA_STAGE_URL, THURAYA_T2M_PROD_URL, VIKAND,
  VIKAND_DIRECT_LANDING, VIKAND_LANDING, VIKAND_NEW_STAGE_URL, VIKAND_PROD_URL, VIKAND_STAGE_URL,
} from './Constants';

const { getSignupPath } = require('../Services/StorageService');

let landingPath = window.location.pathname;

if (landingPath === '/verify' || landingPath.includes('/app')) {
  if (getSignupPath()) {
    landingPath = getSignupPath();
  }
}

let currentClient = null;

const apiChangeArray = [
  { path: VIKAND_LANDING, appType: VIKAND },
  { path: VIKAND_DIRECT_LANDING, appType: VIKAND },
  { path: THURAYA_LANDING, appType: THURAYA_DOMAIN },
  { path: ONSHIP_LANDING, appType: DOMAIN_ONSHIP },
  { path: ONECARE_LANDING, appType: ONECARE },
  { path: STATION_SATCOM_LANDING, appType: STATIONSATCOM },
  { path: SIGMA_LANDING, appType: SIGMA },
  { path: PIVOTEL_LANDING, appType: DOMAIN_PIVOTEL },
  { path: '/', appType: null },
];

let getIndex = null;

if (landingPath) {
  getIndex = apiChangeArray.findIndex((elem) => elem.path === landingPath);
}

if (getIndex !== null && getIndex !== -1) {
  currentClient = apiChangeArray[getIndex].appType;
}

const https = 'https://';

if ([https + ONSHIP_PROD_URL, https + ONSHIP_STAGE_URL, https + ONSHIP_NEW_STAGE2_URL].includes(window.location.origin)
  || [DOMAIN_ONSHIP, DOMAIN_ONSHIP_STAGE].includes(window.location.pathname)) {
  currentClient = DOMAIN_ONSHIP;
}
// Reminder: DNS will change
if ([https + VIKAND_PROD_URL, https + VIKAND_STAGE_URL,https + VIKAND_NEW_STAGE_URL].includes(window.location.origin) || [VIKAND].includes(window.location.pathname)) {
  currentClient = VIKAND;
}

if ([https + THURAYA_T2M_PROD_URL, https + THURAYA_STAGE_URL, https + STAGE_8_THURAYA_URL].includes(window.location.origin)
  || [THURAYA_DOMAIN].includes(window.location.pathname)) {
  currentClient = THURAYA_DOMAIN;
}

if ([https + ONECARE_PROD_URL, https + ONECARE_STAGE_URL,https + ONECARE_PROD_URL_NEW].includes(window.location.origin) || [ONECARE].includes(window.location.pathname)) {
  currentClient = ONECARE;
}

if ([https + STATIONSATCOM_PROD_URL].includes(window.location.origin) || [DOMAIN_STATIONSATCOM].includes(window.location.pathname)) {
  currentClient = STATIONSATCOM;
}

if ([https + PIVOTEL_PROD_URL, https + PIVOTEL_STAGE_URL].includes(window.location.origin) || [DOMAIN_PIVOTEL].includes(window.location.pathname)) {
  currentClient = DOMAIN_PIVOTEL;
}

const appType = currentClient;

export default appType;
